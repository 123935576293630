/* Reset */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Main Container Styles */
.home-container {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to start from the top */
  text-align: center;
  color: #fff;
  position: relative;
  flex-direction: column;
  padding-top: 50px; /* Add padding at the top */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

/* Welcome Section Styles */
.welcome {
  max-width: 90%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  border-radius: 10px;
  z-index: 2;
  animation: fadeIn 2s ease-in-out forwards;
  border: 2px solid #fff; /* White border for better contrast */
  text-align: center;
  color: #fff; /* Ensure the text is white for better contrast */
  margin: 20px auto; /* Center the container and add some margin */
  position: relative; /* Ensure proper layering */
  top: 50px; /* Move downward */
}

.welcome1 {
  max-width: 90%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  border-radius: 10px;
  z-index: 2;
  animation: fadeIn 2s ease-in-out forwards;
  border: 2px solid #fff; /* White border for better contrast */
  text-align: center;
  color: #fff; /* Ensure the text is white for better contrast */
  margin: 20px auto; /* Center the container and add some margin */
  position: relative; /* Ensure proper layering */
  top: 70px; /* Move downward */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Heading Styles */
.home-text {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #fff; /* Ensure the text is white for better contrast */
}

/* Description Styles */
.home-description {
  font-size: 1.2rem;
  margin-top: 0;
  color: #fff; /* Ensure the text is white for better contrast */
}

/* WhatsApp Icon Styles */
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999; /* Ensure it appears above other content */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .home-container {
    height: 80vh; /* Adjust height for smaller screens */
  }

  .home-text {
    font-size: 2rem;
  }

  .home-description {
    font-size: 1rem;
  }
  
  /* Adjust WhatsApp Icon position */
  .whatsapp-icon {
    bottom: 10px;
    right: 10px;
  }
}

@media (max-width: 576px) {
  .welcome, .welcome1 {
    max-width: 95%;
    padding: 15px;
  }

  .home-text {
    font-size: 1.5rem;
  }

  .home-description {
    font-size: 0.9rem;
  }

  .welcome {
    top: 40px; /* Adjust for smaller screens */
  }

  .welcome1 {
    top: 60px; /* Adjust for smaller screens */
  }

  .home-container {
    height: 70vh; /* Further adjust height for even smaller screens */
  }
  
  /* Adjust WhatsApp Icon position */
  

  /* Navbar general styles */
/* Existing styles remain the same */

.whatsapp-icon {
  display: none; /* Initially hide the WhatsApp icon */
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: green;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  z-index: 999; /* Ensure it appears above other content */
}

@media (max-width: 768px) {
  .whatsapp-icon {
    display: block; /* Display the WhatsApp icon on small screens */
    position: fixed;
    bottom: 5%;
    right: 50%;
    transform: translate(50%, 50%);
  }
}

}

/* Downward Arrow Styles */
.arrow-down {
  position: absolute;
  bottom: 20px;
  width: 250px; /* Increase the width of the arrow */
  height: 150px; /* Increase the height of the arrow */
  left: 50%;
  transform: translateX(-50%);
  animation: floatArrow 2s infinite ease-in-out;
  z-index: 2;
  cursor: pointer;
}

.arrow-down svg {
  width: 100%; /* Make the SVG width responsive */
  height: 100%; /* Make the SVG height responsive */
  fill: yellow; /* Change the color to yellow */
  cursor: pointer;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .arrow-down {
    width: 40px; /* Adjust width for smaller screens */
    height: 40px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 576px) {
  .arrow-down {
    width: 30px; /* Further adjust width for even smaller screens */
    height: 30px; /* Further adjust height for even smaller screens */
  }
}
