.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .whatsapp-icon img {
    width: 50px;
    height: auto;
  }
  