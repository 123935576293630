/* Services.css */

#services {
  padding: 80px 0;
  background-color: #f9f9f9; /* Background color for the services section */
  text-align: center;
  
  margin-bottom: 0;
  margin-top: -4%;
}

.title h2 {
  margin-bottom: 30px;
  margin-top: -10px;
  font-size: 6rem; /* Default font size for large screens */
  transition: font-size 0.3s, margin 0.3s;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the service cards */
  gap: 20px; /* Add gap between the cards */
}

.col-md-3 {
  flex: 0 0 calc(25% - 20px); /* Four cards per row */
  margin-bottom: 40px;
}

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
  transition: transform 0.3s, box-shadow 0.3s;
  height: 100%; /* Ensure cards have the same height */
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-5%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-title {
  font-size: 1.8rem; /* Increase title font size */
  font-weight: bold;
  color: #333;
}

.card-text {
  font-size: 1.2rem; /* Increase text font size */
  color: #666;
  line-height: 1.6; /* Increase line height for better readability */
  flex-grow: 1;
}

.read-more-button {
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.read-more-button:hover {
  background-color: #555;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .col-md-3 {
    flex: 0 0 calc(33.333% - 20px); /* Three cards per row on large screens */
  }

  .title h2 {
    font-size: 5rem; /* Adjust font size for large screens */
    margin-top: -45px; /* Adjust top margin for large screens */
    margin-bottom: 25px; /* Adjust bottom margin for large screens */
  }
}

@media (max-width: 992px) {
  .col-md-3 {
    flex: 0 0 calc(50% - 20px); /* Two cards per row on medium screens */
  }

  .title h2 {
    font-size: 4rem; /* Adjust font size for medium screens */
    margin-top: -40px; /* Adjust top margin for medium screens */
    margin-bottom: 20px; /* Adjust bottom margin for medium screens */
  }
}

@media (max-width: 768px) {
  .col-md-3 {
    flex: 0 0 calc(50% - 20px); /* Two cards per row on small screens */
  }

  .card-body {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .card-title {
    font-size: 1.5rem; /* Reduce font size for smaller screens */
  }

  .card-text {
    font-size: 1rem; /* Reduce font size for smaller screens */
  }

  .title h2 {
    font-size: 3.8rem; /* Adjust font size for small screens */
    margin-top: -30px; /* Adjust top margin for small screens */
    margin-bottom: 20px; /* Adjust bottom margin for small screens */
  }
}

@media (max-width: 576px) {
  .col-md-3 {
    flex: 0 0 calc(100% - 20px); /* One card per row on extra small screens */
  }

  .card-body {
    padding: 15px; /* Further adjust padding for very small screens */
  }

  .card-title {
    font-size: 1.2rem; /* Further reduce font size */
  }

  .card-text {
    font-size: 0.9rem; /* Further reduce font size */
  }

  .card:hover {
    transform: translateY(0); /* Remove the translate effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust box shadow */
  }

  .title h2 {
    font-size: 3rem; /* Adjust font size for extra small screens */
    margin-top: -20px; /* Adjust top margin for extra small screens */
    margin-bottom: 15px; /* Adjust bottom margin for extra small screens */
  }
}

@media (max-width: 480px) {
  .card-body {
    padding: 10px; /* Further adjust padding for very small screens */
  }

  .card-title {
    font-size: 1rem; /* Further reduce font size */
  }

  .card-text {
    font-size: 0.8rem; /* Further reduce font size */
  }

  .title h2 {
    font-size: 2.8rem; /* Adjust font size for very small screens */
    margin-top: -15px; /* Adjust top margin for very small screens */
    margin-bottom: 10px; /* Adjust bottom margin for very small screens */
  }
}
