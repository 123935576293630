.projects-title {
  text-align: center;
  font-size: 2.5em;
  margin-top: -30px;
  color: #333; /* Black color */
}

.projects-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.projects-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 10px;
  padding: 15px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.projects-item:hover {
  transform: scale(1.05);
}

.projects-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.project-title {
  margin: 10px 0;
  font-size: 1.5em;
  color: #333; /* Black color */
}

.project-description {
  font-size: 16px;
  color: #555; /* Dark gray color */
}

.read-more-button {
  background: #93be42; /* Yellow color */
  border: none;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
}

.read-more-button:hover {
  background: #0022e0; /* Darker yellow color */
}

.show-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.show-more {
  background: #440ae4; /* Yellow color */
  border: none;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
}

.show-more:hover {
  background: #6c6a77; /* Darker yellow color */
}

@media (max-width: 768px) {
  .projects-gallery {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
  }
}
