.footer {
  background-color: #FFD700; /* Changed to company color */
  color: #333; /* Changed text color for better contrast */
  padding: 20px 0; /* Reduce padding for smaller screens */
  margin: 0;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .col-md-4 {
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
}

.footer h5 {
  font-size: 18px;
  margin-bottom: 15px;
}

.footer p {
  line-height: 1.6;
}

.footer ul {
  padding: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li i {
  margin-right: 10px;
}

.footer ul li a {
  color: #333; /* Changed link color */
  text-decoration: none;
  transition: color 0.3s;
}

.footer ul li a:hover {
  color: #ca1515; /* Darken link color on hover */
}

.footer-bottom {
  background-color: #222;
  padding: 10px 0; /* Reduce padding for smaller screens */
  margin-top: 20px;
  color: #d3c5c5;
}

.footer-bottom p {
  margin: 0;
}

.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .footer .row {
    flex-direction: column;
    align-items: center;
  }

  .footer .col-md-4 {
    max-width: 100%;
    text-align: center;
  }
}

/* General styles for the paragraph */
.responsive-paragraph {
  padding: 20px;
  margin: 20px auto;
  width: 80%;
  max-width: 300px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1.1em;
  line-height: 1.6;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

/* Hover effect */
.responsive-paragraph:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments for tablets and below */
@media (max-width: 768px) {
  .responsive-paragraph {
    width: 90%;
    padding: 15px;
    font-size: 1em;
    margin: 10px auto; /* Adjust margin for better spacing on smaller screens */
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 576px) {
  .responsive-paragraph {
    width: 95%;
    padding: 10px;
    font-size: 0.9em;
    margin: 5px auto; /* Adjust margin for better spacing on smaller screens */
  }
}
