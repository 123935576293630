.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: white; /* Changed to company color */
  min-height: 100vh;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.contact-info {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  margin-bottom: 40px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.icon {
  margin-right: 10px;
  color: #555;
}

.info-text {
  font-size: 1.1rem;
  color: #555;
}

.map {
  width: 100%;
  height: 400px;
  border: 0;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .contact-info {
      width: 90%; /* Adjusted width for smaller screens */
  }
}
