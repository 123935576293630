/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
}

.booking-container {
  max-width: 800px;
  margin: 50px auto;
  background-color: #cff03e;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

/* Form styles */
form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  width: calc(33.333% - 20px);
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="time"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #1b0e0e;
  box-sizing: border-box;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #2f44b9;
  color: #f3e8e8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #555;
}

/* Animation styles */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.booking-container {
  animation: slideIn 0.5s ease forwards;
}

/* Media queries */
@media (max-width: 768px) {
  .form-group {
    width: calc(50% - 20px);
  }
}

@media (max-width: 500px) {
  .form-group {
    width: 100%;
  }
}