.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.testimonials-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.testimonial {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.testimonial:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.testimonial img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.testimonial h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.testimonial p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.testimonial p:last-child {
  font-size: 0.9rem;
  color: #777;
  font-style: italic;
}