/* About.css */
.about-container {
  max-width: 1200px;
  border-radius: 10px;
  margin: 0 auto; /* Center the container */
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  margin-top: 10%;
  background-color: rgba(0, 0, 0, 0.8); /* Darkish background color */
}

h1 {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
  
}

.company-description {
  padding: 20px;
 
  margin: 20px auto;
  
  width: 80%;
  max-width: 200px;
  background-color: #f0eee7;
  border: 1px solid #bebbbb;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
  font-size: 1.1em;
  line-height: 1.6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.company-description:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mission-vision-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  background-color: #a09e95;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mission,
.vision {
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mission h2,
.vision h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.mission p,
.vision p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.team {
  text-align: center;
  margin-top: 40px;
}

.team h2 {
  color: #555450;
  margin-bottom: 20px;
}

.team-images {
  display: flex;
  justify-content: space-between;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.team-member .team-image {
  max-width: 100px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .company-description {
      width: 90%;
      padding: 15px;
      margin: 10px auto;
      font-size: 1em;
  }

  .mission-vision-container {
      flex-direction: column;
  }
}

@media (max-width: 576px) {
  .company-description {
      width: 100%;
      padding: 10px;
      margin: 10px auto;
      font-size: 0.9em;
  }
}
.about-container h1 {
  margin-top: 0; /* Ensure no extra space at the top */
  margin-bottom: 20px; /* Increase space between the heading and the paragraph */
}