/* Navbar general styles */
.navbar {
  padding: 1rem; /* Increase padding for larger navbar */
  border: none; /* Remove any border */
  box-shadow: none; /* Remove any shadow */
  background-color: transparent; /* Ensure background is transparent */
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  list-style: none;
}

.nav-item {
  margin-left: 15px; /* Adjusted margin between navbar items */
}

.nav-link {
  display: flex;
  align-items: center;
  font-size: 1rem; /* Default font size */
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Default link color */
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
}

.nav-link:hover {
  color: #007bff; /* Add hover color for better UX */
}

.nav-link.btn {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  font-family: 'Lobster', cursive;
  font-size: 1.5rem;
  color: #333; /* Navbar brand color */
  display: flex;
  align-items: center;
  text-decoration: none; /* Remove underline from brand */
}

.navbar-logo {
  height: 80px; /* Increased height for better visibility */
  margin-right: 10px; /* Add some margin between logo and text */
  background-color: rgb(15, 15, 15);
  border-radius: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above all other content */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 400px; /* Set a max width for the modal */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.navbar-toggler {
  display: none; /* Hide the toggler by default */
}

/* Media Queries for responsive design */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .nav-item {
    margin-left: 0;
    margin-bottom: 10px; /* Add margin between navbar items */
  }

  .navbar-logo {
    height: 60px;
    margin: 0 auto 10px;
  }

  .navbar-toggler {
    display: block; /* Display the toggler on small screens */
    border: none;
    background: none;
    font-size: 1.5rem;
  }

  .collapse {
    display: none; /* Hide the navbar items by default on small screens */
  }

  .collapse.show {
    display: flex; /* Show the navbar items when toggled */
    flex-direction: column;
  }

  .nav-link {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
    width: 100%;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Add bottom border for better separation */
  }

  .nav-link.btn {
    width: 100%;
    text-align: center;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    margin-top: 10px;
  }
}
